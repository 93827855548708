// Atlassian
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'
import { Checkbox } from '@atlaskit/checkbox'

// Interface
import { Field, useFormContext } from '@royan-co/user-interface'

// Utils
import { findOption } from 'utils/helpers'

// Components
import Card from 'components/UI/Card'
import { modeOptions, truckTypes, containerTypes } from 'components/Shipments/constants'

const LineRouteCreateEditForm = () => {
  const { watch } = useFormContext()

  const mode = watch('shipment_type')

  return (
    <>
      <Card className="mb-6">
        <Field label="Name" name="name" className="!mt-0">
          {({ fieldProps, error }) => (
            <>
              <Textfield placeholder="Name your route" {...fieldProps} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Field>

        <Field
          label="Shipment type"
          type="select"
          name="shipment_type"
          transform={{
            output: (opt) => (opt?.value !== undefined ? opt.value : null),
            input: (v) => findOption(modeOptions, v),
          }}
          className="!mt-4"
        >
          {({ fieldProps }) => (
            <Select
              isClearable={true}
              options={modeOptions}
              placeholder="Choose a mode"
              {...fieldProps}
            />
          )}
        </Field>

        {[0, 1].includes(mode) && (
          <Field
            type="select"
            label="Loading method"
            name="type_of_collies"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(mode === 1 ? containerTypes : truckTypes, v),
            }}
          >
            {({ fieldProps }) => (
              <Select
                isClearable={true}
                options={mode === 1 ? containerTypes : truckTypes}
                placeholder="Choose a service"
                {...fieldProps}
              />
            )}
          </Field>
        )}

        <Field type="checkbox" name="default" defaultValue={false}>
          {({ fieldProps }) => <Checkbox label="Default route" {...fieldProps} />}
        </Field>
      </Card>
    </>
  )
}

export default LineRouteCreateEditForm
