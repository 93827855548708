import axios from 'services/Request'

export function getLines(params) {
  return axios.get('/api/lines', { params })
}

export function lookupLine(pol, pod) {
  return axios.get(`/api/lines/lookup/${pol}/${pod}`)
}

export function getLine(lineId) {
  return axios.get(`/api/lines/${lineId}`)
}

export function createLine(data) {
  return axios.post('/api/lines', data)
}

export function editLine(lineId, data) {
  return axios.put(`/api/lines/${lineId}`, data)
}

export function deleteLine(lineId) {
  return axios.delete(`/api/lines/${lineId}`)
}

export function getLineRoutes(lineId) {
  return axios.get(`/api/lines/${lineId}/routes`)
}

export function getLineRoute(routeId) {
  return axios.get(`/api/routes/${routeId}`)
}

export function createLineRoute(lineId, data) {
  return axios.post(`/api/lines/${lineId}/routes`, data)
}

export function editLineRoute(routeId, data) {
  return axios.put(`/api/routes/${routeId}`, data)
}

export function deleteLineRoute(routeId) {
  return axios.delete(`/api/routes/${routeId}`)
}
