export const typeOptions = [
  { label: 'Hub to zone', value: 0 },
  { label: 'Zone to hub', value: 1 },
  { label: 'Zone to zone', value: 2 },
  { label: 'Hub to hub', value: 3 },
  { label: 'Accessory', value: 4 },
  { label: 'Airport to airport', value: 5 },
  { label: 'Port to port', value: 6 },
  { label: 'Hub to port', value: 7 },
  { label: 'Zone to port', value: 8 },
  { label: 'Port to zone', value: 9 },
  { label: 'Rail to rail', value: 10 },
  { label: 'Hub to rail', value: 11 },
  { label: 'Rail to hub', value: 12 },
  { label: 'Rail to zone', value: 13 },
  { label: 'Zone to rail', value: 14 },
  { label: 'Rail to port', value: 15 },
  { label: 'Port to rail', value: 16 },
  { label: 'Hub to airport', value: 17},
]

export const calculationMethodOptions = [
  { label: 'Fix', value: 0 },
  { label: 'Gross weight', value: 1 },
  { label: 'Chargeable weight', value: 2 },
  { label: 'Freight charge', value: 3 },
  { label: 'Custom code', value: 4, isDisabled: true },
  { label: 'Number of collies', value: 5 },
  { label: 'Transportation unit', value: 6 },
]

export const calculationMethodShortLabelOptions = [
  { label: 'Fix', value: 0 },
  { label: 'G.W.', value: 1 },
  { label: 'CH.W.', value: 2 },
  { label: 'F.C.', value: 3 },
  { label: 'C.C.', value: 4, isDisabled: true },
  { label: 'Collies', value: 5 },
  { label: 'T.U.', value: 6 },
]

export const formatOptions = [
  { label: 'Table rate', value: 0, appearance: 'inprogress' },
  { label: 'Flat rate', value: 1, appearance: 'inprogress' },
  { label: 'Percentage', value: 2, appearance: 'inprogress', isDisabled: true },
]

export const allowCalculationOptions = [
  { label: 'No', value: 0, appearance: 'moved' },
  { label: 'Yes', value: 1, appearance: 'success' },
]

export const serviceTypeOptions = [
  { label: 'General cargo', value: 'GEN' },
  { label: 'Perishable goods', value: 'PER' },
  { label: 'Dangerous goods', value: 'DGR' },
  { label: 'Chemical goods', value: 'CHE' },
  { label: 'Pharmaceutical goods', value: 'PHA' },
]

export const calculationOnOptions = [
  { label: 'Cubic meter weight', value: 0 },
  { label: 'Linear meter weight', value: 1 },
  { label: 'Maximum of CBM and LDM', value: 2 },
]

export const calculationOnNotStackableOptions = [
  { label: 'CBM', value: 0 },
  { label: 'LDM', value: 1 },
]

export const roundingOptions = [
  { label: 'Decimal after the comma', value: 0 },
  { label: 'Integer', value: 1 },
  { label: 'Decimal', value: 2 },
  { label: 'Hundred', value: 3 },
  { label: 'Minus Hundred', value: 4 },
]

export const validCargoOptions = [
  { label: 'Only dense', value: 0 },
  { label: 'Only voluminous', value: 1 },
  { label: 'Only normal', value: 2 },
]

export const discountCalculationMethodOptions = [
  { label: '%', value: 0 },
  { label: 'Kgs', value: 1 },
]

export const calculationUnitOptions = [
  { label: 'Half chargeable Kgs', value: 0.5 },
  { label: 'One chargeable Kgs', value: 1 },
  { label: 'One hundred chargeable Kgs', value: 100 },
  { label: 'One thousand chargeable Kgs', value: 1000 },
]

export const calculationLDMValue = [
  1200, 1250, 1400, 1450, 1500, 1550, 1600, 1650, 1700, 1750, 1800, 1850, 2000, 5000,
].map((LDM) => ({ label: LDM, value: LDM }))

export const calculationCBMValue = [167, 200, 250, 280, 300, 330, 333, 1000].map((CBM) => ({
  label: CBM,
  value: CBM,
}))
