import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Atlaskit
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'

// Helpers
import { findOption } from 'utils/helpers'

// Constants
import { modeOptions } from 'components/Shipments/constants'

// User Interface
import { Grid, Timeline, TimelineItem, DotsIcon } from '@royan-co/user-interface'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import RowTable from 'components/UI/RowTable'
import DropdownLink from 'components/UI/DropdownLink'

const lozengeType = (shipmentType, type) => {
  if (shipmentType === 0) {
    return type === 1 ? 'FTL' : 'LTL'
  } else if (shipmentType === 1) {
    return type === 1 ? 'FCL' : 'LCL'
  }
}

const RouteCard = ({ route, deleteCallback }) => {
  const { lineId } = useParams()

  const rows = useMemo(() => {
    const items = [
      {
        title: 'Shipment type',
        content: findOption(modeOptions, route?.shipment_type)?.label,
      },
    ]

    if ([0, 1].includes(route?.shipment_type)) {
      items.push({
        title: 'Loading method',
        content: <Lozenge>{lozengeType(route?.shipment_type, route?.type_of_collies)}</Lozenge>,
      })
    }

    items.push({
      title: 'Default',
      content: route?.default ? (
        <Lozenge appearance="success">Yes</Lozenge>
      ) : (
        <Lozenge appearance="default">No</Lozenge>
      ),
    })

    return items
  }, [route?.shipment_type, route?.type_of_collies, route?.default])

  return (
    <>
      <Card>
        <div className="flex justify-between">
          <CardTitle className="mb-2 mt-1 text-base">{route?.name}</CardTitle>
          <DropdownMenu
            placement="bottom-end"
            trigger={({ triggerRef, ...props }) => (
              <Button
                {...props}
                appearance="subtle"
                children={<DotsIcon label="more" />}
                ref={triggerRef}
              />
            )}
          >
            <DropdownItemGroup>
              <DropdownLink
                children="Edit"
                to={`/panel/sales-machine/lines/${lineId}/routes/${route?.id}/edit`}
              />
              <DropdownItem children="Delete" onClick={() => deleteCallback(route?.id)} />
            </DropdownItemGroup>
          </DropdownMenu>
        </div>

        <Grid>
          <Grid.Col sm={6}>
            <RowTable rows={rows} />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Timeline>
              {route?.legs?.map((leg, index) => (
                <TimelineItem key={index} title={`${leg?.departure} to ${leg?.arrival}`} />
              ))}
            </Timeline>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  )
}

export default RouteCard
