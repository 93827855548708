import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// API
import { createLineRoute, editLineRoute } from 'features/lines/api/requests'

// Hooks
import { useLine } from 'features/lines/hooks/useLine'
import useLineRoutes from 'features/lines/hooks/useLineRoutes'
import useLineRoute from 'features/lines/hooks/useLineRoute'

// Atlaskit
import PageHeader from '@atlaskit/page-header'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Button, { LoadingButton } from '@atlaskit/button'

// Interface
import { Grid, Form, WarningBannerField, useFlags } from '@royan-co/user-interface'

// Components
import LineRouteCreateEditForm from 'features/lines/components/Create/Routes/Form'
import LineRouteCreateEditLegs from 'features/lines/components/Create/Routes/Legs'

// Utils
import { handleErrorOnSubmit } from 'utils/errors'

// Helpers
import { getFilled } from 'utils/helpers'

const LineRouteCreateEditPage = () => {
  const { lineId, routeId } = useParams()
  const { showSuccessFlag } = useFlags()
  const navigate = useNavigate()

  const pageTitle = `${routeId ? 'Edit' : 'New'} route`

  const {
    data: line,
    isLoading: isLoadingLine,
    isError: isErrorLine,
    refetch: refetchLine,
  } = useLine(lineId, { enabled: !!lineId })
  const {
    data: route,
    isLoading: isLoadingRoute,
    isError: isErrorRoute,
    refetch: refetchRoute,
    invalidate: invalidateRoute,
  } = useLineRoute(routeId, { enabled: !!routeId })
  const { invalidate } = useLineRoutes(lineId, { enabled: false })

  const routeMutation = useMutation(
    !!routeId ? editLineRoute.bind(null, routeId) : createLineRoute.bind(null, lineId)
  )

  const isLoading = useMemo(
    () => (routeId ? isLoadingRoute : isLoadingLine),
    [routeId, isLoadingLine, isLoadingRoute]
  )

  const isError = useMemo(() => {
    return routeId ? isErrorRoute : isErrorLine
  }, [routeId, isErrorLine, isErrorRoute])

  const defaultValues = useMemo(
    () => ({
      name: getFilled(route, 'name', ''),
      shipment_type: getFilled(route, 'shipment_type', ''),
      type_of_collies: getFilled(route, 'type_of_collies', ''),
      default: getFilled(route, 'default', false),
      legs: getFilled(route, 'legs', []),
    }),
    [route]
  )

  const handleSubmit = (formData, { setError }) => {
    routeMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, formData)
      },
      onSuccess: (res) => {
        invalidate()
        invalidateRoute()
        showSuccessFlag(res?.message)
        navigate(`/panel/sales-machine/lines/${line?.id}/routes`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid gutter={24} className="w-full">
        <Grid.Col md={10} lg={8} xl={6} offsetMd={1} offsetLg={2} offsetXl={3}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem text="Sales machine" />
                <BreadcrumbsItem text="Lines" to="/panel/sales-machine/lines" component={Link} />
                {isLoading ? (
                  <Spinner size={'small'} />
                ) : (
                  <>
                    <BreadcrumbsItem
                      text={`${line?.departure_country} to ${line?.arrival_country}`}
                      to={`/panel/sales-machine/lines/${lineId}`}
                      component={Link}
                    />
                    <BreadcrumbsItem
                      text="Routes"
                      to={`/panel/sales-machine/lines/${lineId}/routes`}
                      component={Link}
                    />
                  </>
                )}
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="my-8">
            {isLoading ? (
              <div className="text-center my-8">
                <Spinner />
              </div>
            ) : isError ? (
              <Grid gutter={24} className="w-full">
                <Grid.Col md={6} offsetMd={3}>
                  <SectionMessage appearance="warning" title="Something wrong. Please retry.">
                    <Button
                      onClick={() => {
                        refetchLine()
                        refetchRoute()
                      }}
                    >
                      Retry
                    </Button>
                  </SectionMessage>
                </Grid.Col>
              </Grid>
            ) : (
              <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
                {() => (
                  <>
                    <LineRouteCreateEditForm />

                    <LineRouteCreateEditLegs />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={false}
                      // isDisabled={true}
                    >
                      {routeId ? 'Edit' : 'Create'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default LineRouteCreateEditPage
