import { useCallback, useEffect, useMemo } from 'react'

// Utils
import { findOption, getCountry, transformSelectField } from 'utils/helpers'
import { formatOptions, serviceTypeOptions, typeOptions } from '../constants'

// Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useRegistryAirlines from 'hooks/useRegistryAirlines'

// Atlassian
import Select, { CountrySelect } from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'
import { Checkbox } from '@atlaskit/checkbox'

// Interfaces
import { Field, Grid, useFormContext, useFormValues } from '@royan-co/user-interface'

// Components
import Card from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import EnumerationField from '../List/EnumerationField'
import RatesAirportsField from '../List/AirportsField'
import RegistrySelect from 'components/Registries/RegistrySelect'
import { containerTypes, modeOptions, truckTypes } from 'components/Shipments/constants'

const RateCreationGeneralInformationFields = () => {
  const { setValue } = useFormContext()
  const {
    from_to: type,
    shipment_type: mode,
    multiple_pod: multiplePOD,
    multiple_pol: multiplePOL,
    arrival_airport: arrivalAirport,
    departure_airport: departureAirport,
  } = useFormValues()

  const useAirline = useMemo(() => type === 5, [type])

  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const { registryAirlineOptions, isLoadingRegistryAirlines } = useRegistryAirlines(useAirline)

  const resetDeparturePlaces = useCallback(
    () =>
      [
        'departure_country',
        'departure_hub',
        'departure_airport',
        'departure_port',
        'departure_station',
      ].forEach((item) => {
        setValue(item, null)
      }),
    [setValue]
  )

  const resetArrivalPlaces = useCallback(
    () =>
      [
        'arrival_country',
        'arrival_hub',
        'arrival_airport',
        'arrival_port',
        'arrival_station',
      ].forEach((item) => {
        setValue(item, null)
      }),
    [setValue]
  )

  useEffect(() => {
    if (![0, 1].includes(mode)) setValue('type_of_collies', null)
    if (multiplePOD) {
      resetArrivalPlaces()
      setValue('transit_time', '')
    }
    if (multiplePOL) {
      resetDeparturePlaces()
      setValue('transit_time', '')
    }
    if (!useAirline) setValue('airline', null)
  }, [
    useAirline,
    mode,
    multiplePOD,
    multiplePOL,
    resetArrivalPlaces,
    resetDeparturePlaces,
    setValue,
  ])

  return (
    <Card className="mb-6">
      <Field isRequired label="Name" className="!mt-0" name="name">
        {({ fieldProps, error }) => (
          <>
            <Textfield placeholder="Type a name" {...fieldProps} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Field
        isRequired
        type="select"
        name="supplier"
        label="Supplier"
        className="!mt-4"
        // onValueChange={() => setValue('contacts', null)}
      >
        {({ fieldProps, error }) => (
          <>
            <RegistrySelect {...fieldProps} registryType="supplier" />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Field>

      <Grid gutter={0} gutterSm="md">
        <Grid.Col sm={6}>
          <Field
            isRequired
            label="Type"
            type="select"
            name="from_to"
            className="!mt-4"
            transform={transformSelectField(typeOptions)}
            onValueChange={(opt) => {
              if (opt?.value !== type) {
                resetArrivalPlaces()
                resetDeparturePlaces()
              }
            }}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={typeOptions}
                  placeholder="Choose a type"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field
            isRequired
            label="Format"
            type="select"
            name="type"
            className="!mt-4"
            transform={transformSelectField(formatOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={formatOptions}
                  placeholder="Choose a format"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>
      </Grid>

      <Grid grow gutter={0} gutterSm="md">
        <Grid.Col sm={6}>
          <Field
            label="Service"
            type="select"
            name="cost_code"
            className="!mt-4"
            transform={transformSelectField(getServiceCodeOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  isLoading={isLoadingShipmentEnumeration}
                  options={getServiceCodeOptions}
                  placeholder="Choose a cost code"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        {useAirline && (
          <Grid.Col sm={6}>
            <Field
              label="Airline"
              type="select"
              name="airline"
              className="!mt-4"
              transform={transformSelectField(registryAirlineOptions)}
            >
              {({ fieldProps }) => (
                <Select
                  isClearable={true}
                  isLoading={isLoadingRegistryAirlines}
                  options={registryAirlineOptions}
                  placeholder="Choose an airline"
                  {...fieldProps}
                />
              )}
            </Field>
          </Grid.Col>
        )}
      </Grid>

      <Grid gutter={0} gutterSm="md">
        <Grid.Col sm={6}>
          <Field label="Valid from" name="start_date" className="!mt-4">
            {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
          </Field>
        </Grid.Col>

        <Grid.Col sm={6}>
          <Field label="Valid to" name="end_date" className="!mt-4">
            {({ fieldProps }) => <DatePicker placeholder=" " {...fieldProps} />}
          </Field>
        </Grid.Col>
      </Grid>

      <Divider className="mt-8 mb-6" />

      {type !== null && (
        <>
          <Grid gutter={0} gutterSm="md">
            <Grid.Col sm={6}>
              <Field
                type="checkbox"
                label="Multiple places of loading"
                name="multiple_pol"
                className="!mt-0"
              >
                {({ fieldProps }) => <Checkbox label="Allow multiple POL" {...fieldProps} />}
              </Field>
            </Grid.Col>

            <Grid.Col sm={6}>
              <Field
                type="checkbox"
                label="Multiple places of delivery"
                name="multiple_pod"
                className="!mt-0"
              >
                {({ fieldProps }) => <Checkbox label="Allow multiple POD" {...fieldProps} />}
              </Field>
            </Grid.Col>
          </Grid>

          <Grid grow gutter={0} gutterSm="md">
            {!multiplePOL && (
              <Grid.Col sm={6}>
                {[1, 2, 8, 14].includes(type) && (
                  <Field
                    type="select"
                    label="Place of loading"
                    name="departure_country"
                    transform={{
                      input: (v) => getCountry(v),
                      output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
                    }}
                  >
                    {({ fieldProps: { ref, ...fieldProps }, error }) => (
                      <>
                        <CountrySelect isClearable placeholder="Country" {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                )}

                {[0, 3, 7, 11, 17].includes(type) && (
                  <EnumerationField
                    label="Place of loading"
                    name="departure_hub"
                    optionsKey="hubs"
                  />
                )}

                {[5].includes(type) && (
                  <RatesAirportsField
                    label="Place of loading"
                    name="departure_airport"
                    defaultValue={departureAirport}
                  />
                )}

                {[6, 9, 16].includes(type) && (
                  <EnumerationField
                    label="Place of loading"
                    name="departure_port"
                    optionsKey="ports"
                  />
                )}

                {[10, 12, 13, 15].includes(type) && (
                  <EnumerationField
                    label="Place of loading"
                    name="departure_station"
                    optionsKey="stations"
                  />
                )}
              </Grid.Col>
            )}

            {!multiplePOD && (
              <Grid.Col sm={6}>
                {[0, 2, 9, 13].includes(type) && (
                  <Field
                    type="select"
                    label="Place of delivery"
                    name="arrival_country"
                    transform={{
                      input: (v) => getCountry(v),
                      output: (opt) => (opt?.abbr !== undefined ? opt.abbr : null),
                    }}
                  >
                    {({ fieldProps: { ref, ...fieldProps }, error }) => (
                      <>
                        <CountrySelect isClearable placeholder="Country" {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                )}

                {[1, 3, 12].includes(type) && (
                  <EnumerationField
                    label="Place of delivery"
                    name="arrival_hub"
                    optionsKey="hubs"
                  />
                )}

                {[5, 17].includes(type) && (
                  <RatesAirportsField
                    label="Place of delivery"
                    name="arrival_airport"
                    defaultValue={arrivalAirport}
                  />
                )}

                {[6, 7, 8, 15].includes(type) && (
                  <EnumerationField
                    label="Place of delivery"
                    name="arrival_port"
                    optionsKey="ports"
                  />
                )}

                {[10, 11, 14, 16].includes(type) && (
                  <EnumerationField
                    label="Place of delivery"
                    name="arrival_station"
                    optionsKey="stations"
                  />
                )}
              </Grid.Col>
            )}
          </Grid>
        </>
      )}

      <Grid grow gutter="md" className="mt-2">
        <Grid.Col sm={6}>
          <Field
            isRequired
            label="Mode"
            type="select"
            name="shipment_type"
            className="!mt-0"
            transform={{
              output: (opt) => (opt?.value !== undefined ? opt.value : null),
              input: (v) => findOption(modeOptions, v),
            }}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={modeOptions}
                  placeholder="Choose a mode"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        {[0, 1].includes(mode) && (
          <Grid.Col sm={6}>
            <Field
              isRequired
              label="Method of loading"
              type="select"
              name="type_of_collies"
              className="!mt-0"
              transform={{
                output: (opt) => (opt?.value !== undefined ? opt.value : null),
                input: (v) => findOption(mode === 1 ? containerTypes : truckTypes, v),
              }}
            >
              {({ fieldProps, error }) => (
                <>
                  <Select
                    isClearable={true}
                    options={mode === 1 ? containerTypes : truckTypes}
                    placeholder="Choose a method"
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>
        )}
      </Grid>

      <Grid grow gutter="md" className="mt-2">
        <Grid.Col sm={6}>
          <Field
            isRequired
            label="Type of shipment"
            type="select"
            name="service_type"
            className="!mt-0"
            transform={transformSelectField(serviceTypeOptions)}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  isClearable={true}
                  options={serviceTypeOptions}
                  placeholder="Choose a type"
                  {...fieldProps}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </Field>
        </Grid.Col>

        {!(multiplePOD || multiplePOL) && (
          <Grid.Col sm={6}>
            <Field label="Transit time" name="transit_time" className="!mt-0">
              {({ fieldProps, error }) => (
                <>
                  <Textfield
                    placeholder="Type a name"
                    {...fieldProps}
                    elemAfterInput={
                      <div className="text-neutral-200 whitespace-nowrap bg-neutral-20 self-stretch flex items-center px-2">
                        Days
                      </div>
                    }
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          </Grid.Col>
        )}
      </Grid>
    </Card>
  )
}

export default RateCreationGeneralInformationFields
