import { useQuery, useQueryClient } from '@tanstack/react-query'

// API
import { getLineRoute } from '../api/requests'

const useLineRoute = (routeId, options = {}) => {
  const queryClient = useQueryClient()

  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['line-route', routeId],
    queryFn: () => getLineRoute(routeId),
    retry: 2,
    staleTime: Infinity,
    ...options,
  })

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['line-route', routeId] })
  }

  return {
    data,
    isLoading,
    isError,
    refetch,
    invalidate,
  }
}

export default useLineRoute
