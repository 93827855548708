import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// Apis
import { createInvoice, editInvoice } from '../api/invoice-requests.js'

// Contexts
import { useAuth } from 'contexts/AuthProvider.js'

// Utils
import { getFilled } from 'utils/helpers'
import { handleErrorOnSubmit } from 'utils/errors'

// Hooks
import { useInvoice, useUpdateInvoice } from '../api/getInvoice.js'

// Atlassian
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import SectionMessage from '@atlaskit/section-message'
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import Button, { LoadingButton } from '@atlaskit/button'

// Interfaces
import { Grid, Form, useFlags, WarningBannerField } from '@royan-co/user-interface'

// Components
import CreateInvoiceFormGeneral from '../components/CreateInvoiceFormGeneral.js'
import CreateInvoiceFormRows from '../components/CreateInvoiceFormRows.js'

const EditActiveInvoice = ({ isCreditNote }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { showSuccessFlag } = useFlags()
  const { invoiceId } = useParams()

  const pageTitle = isCreditNote ? 'New credit note' : 'Edit active invoice'

  const { invoice, isLoading, isError, refetch } = useInvoice(
    invoiceId,
    isCreditNote ? { credit: 1 } : undefined
  )
  const { upsertInvoiceProperties } = useUpdateInvoice(invoiceId)

  const isEditableNumber = useMemo(
    () =>
      ['master', 'admin', 'accounting'].includes(currentUser?.user_level_name?.name)
      [currentUser]
  )

  const defaultValues = useMemo(() => {
    return {
      invoice_number: isCreditNote ? null : getFilled(invoice, 'invoice_number', ''),
      currency: getFilled(invoice, 'currency', ''),
      document_type: getFilled(invoice, 'do', null),
      invoice_date: getFilled(invoice, 'invoice_date', ''),
      payment_condition: getFilled(invoice, 'payment_condition.code', ''),
      bank: getFilled(invoice, 'bank.id_auto', ''),
      notes: getFilled(invoice, 'notes', ''),
      client: invoice?.client?.id_auto
        ? {
          label: invoice?.client?.company_name,
          value: invoice?.client?.id_auto,
          imported: invoice?.client?.arca_imported,
        }
        : null,
      client_address_id: invoice?.client_address_id,
      rows: invoice?.cost_rows?.map((row) => {
        let _row = {}
        if (isCreditNote) _row.cost_id = row?.shipment_cost_id
        if (isCreditNote && row?.shipment_cost_accessories_id)
          _row.accessory_id = row?.shipment_cost_accessories_id
        _row = {
          ..._row,
          id_auto: getFilled(row, 'id_auto', null),
          service_code: getFilled(row, 'cost_code', null),
          description: getFilled(row, 'service', ''),
          quantity: getFilled(row, 'quantity', ''),
          unit_price: getFilled(row, 'unit_amount', ''),
          sell_price: row?.unit_amount * row.quantity || '',
          iva_code: getFilled(row, 'iva_code', null),
          iva_amount: getFilled(row, 'iva_amount', ''),
          checked: !!(row?.unit_amount * row.quantity),
        }
        if (isCreditNote) _row.sell_price = getFilled(row, 'amount', '')

        return _row
      }),
    }
  }, [invoice, isCreditNote])

  const editInvoiceMutation = useMutation((data) =>
    isCreditNote ? createInvoice(data) : editInvoice(invoiceId, '', data)
  )
  const onSubmitIssueInvoice = (data, { setError }) => {
    let formData = {
      ...data,
      rows: data.rows.filter((row) => row.checked).map(({ checked, id, ...row }) => ({ ...row })),
      client: data?.client?.value,
      active_passive: 0,
    }

    if (isCreditNote) formData.original_invoice_number = invoice.invoice_number
    if (!isEditableNumber) delete formData.invoice_number

    editInvoiceMutation.mutate(formData, {
      onError: (e) => {
        handleErrorOnSubmit(e, setError, data)
      },
      onSuccess: (res) => {
        showSuccessFlag(res?.message)
        upsertInvoiceProperties(res.invoice)
        setTimeout(() => {
          navigate(`/panel/active-invoices/${isCreditNote ? res?.invoice?.id_auto : invoiceId}`, {
            replace: true,
          })
        }, 100)
      },
    })
  }

  useEffect(() => {
    return () => {
      if (isCreditNote)
        queryClient.removeQueries({
          queryKey: ['invoice', 'credit', +invoiceId],
        })
    }
  }, [isCreditNote, queryClient, invoiceId])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Grid className="w-full">
        <Grid.Col md={10} xl={8} offsetMd={1} offsetXl={2}>
          <PageHeader
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem text="Panel" to="/panel" component={Link} />
                <BreadcrumbsItem
                  text="Active invoices"
                  to="/panel/active-invoices"
                  component={Link}
                />
                <BreadcrumbsItem
                  text={
                    isLoading ? <Spinner size="xsmall" /> : `Invoice ${invoice?.invoice_number}`
                  }
                  to={`/panel/active-invoices/${invoiceId}`}
                  component={Link}
                />
              </Breadcrumbs>
            }
          >
            {pageTitle}
          </PageHeader>

          <div className="py-4">
            {isLoading ? (
              <div className="flex justify-center my-32">
                <Spinner />
              </div>
            ) : isError ? (
              <SectionMessage
                appearance="warning"
                title="Something wrong on loading data, please retry."
              >
                <Button onClick={() => refetch()}>Retry</Button>
              </SectionMessage>
            ) : (
              <Form onSubmit={onSubmitIssueInvoice} defaultValues={defaultValues}>
                {() => (
                  <>
                    <CreateInvoiceFormGeneral
                      isCreditNote={isCreditNote || invoice?.do_boolean}
                      isEditableNumber={isEditableNumber}
                    />

                    <CreateInvoiceFormRows isCreditNote={isCreditNote} />

                    <WarningBannerField />

                    <LoadingButton
                      className="!table ml-auto mt-6"
                      appearance="primary"
                      type="submit"
                      isLoading={editInvoiceMutation.isLoading}
                    >
                      {isCreditNote ? 'Issue credit note' : 'Edit invoice'}
                    </LoadingButton>
                  </>
                )}
              </Form>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default EditActiveInvoice
