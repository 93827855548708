import { createElement, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'

//Apis
import { createShipment, editShipmentParam } from 'api/shipments'
import { getRegistryContacts } from 'api/registries'

//Utils
import { findOption } from 'utils/helpers'
import { formatMonetaryNumber } from 'utils/numbers'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useShipment from 'hooks/useShipment'

//Contexts
import { useShipmentCreationContext } from 'contexts/ShipmentCreationProvider'

//Atlassian
import Select from '@atlaskit/select'
import Lozenge from '@atlaskit/lozenge'
import { ErrorMessage } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'

//Interfaces
import {
  Field,
  useFlags,
  WizardForm,
  WarningBanner,
  useWizardFormContext,
} from '@royan-co/user-interface'

//Components
import { modes } from 'components/Shipments/Create/modes'
import RowTable from 'components/UI/RowTable'
import ShipmentCreationCargoHeader from 'components/Shipments/Create/CargoHeader'
import Card from 'components/UI/Card'
import RegistrySelect from 'components/Registries/RegistrySelect'

const ShipmentCreationSummaryPage = ({ entity, flagStatus }) => {
  const navigate = useNavigate()
  const { showSuccessFlag } = useFlags()
  const { shipmentId, quotationId } = useParams()
  const { steps, prevStep } = useShipmentCreationContext()
  const { getEnumerationOptionsByKey } = useShipmentsEnumerations()

  const isEditMode = !!shipmentId || !!quotationId
  const entityId = shipmentId || quotationId

  const { upsertShipmentData } = useShipment(entityId, { enabled: isEditMode })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [unknownError, setUnknownError] = useState(null)

  const {
    stepsValue: { routing, cargo, summary },
  } = useWizardFormContext()

  const mode = useMemo(() => modes.find((m) => m.id === routing?.modeType), [routing?.modeType])

  const { isLoading: clientContactsIsLoading, data: clientContacts } = useQuery(
    ['contact', summary?.client?.value],
    () => getRegistryContacts(summary?.client?.value),
    {
      staleTime: Infinity,
      retry: 2,
      enabled: !!summary?.client?.value,
    }
  )

  const registryContactsOptions = useMemo(() => {
    if (!clientContacts) return []

    return clientContacts.map((contact) => ({
      label: contact.full_name,
      value: contact.id_auto,
    }))
  }, [clientContacts])

  const submitData = useMemo(() => {
    let data = {
      cbm: cargo?.cbm,
      numerator: entity,
      reefer: cargo?.reefer,
      weight: cargo?.weight,
      flag_status: flagStatus,
      adr_class: cargo?.adr_class,
      adr_number: cargo?.adr_number,
      adr_goods: cargo?.adr_goods || 0,
      insurance: cargo?.insurance || 0,
      goods_value: cargo?.cargo_value,
      goods_value_currency: cargo?.currency,
      reefer_temperature: cargo?.reefer
        ? `${cargo?.reefer_temperature?.value?.range?.min},${cargo?.reefer_temperature?.value?.range?.max}`
        : null,
      total_collies: cargo?.total_collies,
      shipment_type: mode?.shipmentType,
      type_of_collies: mode?.typeOfCollies,
      quantity_truck:
        mode?.typeOfCollies === 1
          ? cargo?.[mode?.cargoKey].reduce((p, c) => p + c.quantity * 1, 0)
          : 0,
      departure: routing?.placeOfLoading?.label,
      departure_country: routing?.placeOfLoading?.countryAbbr,
      departure_city: routing?.placeOfLoading?.city,
      departure_cap: routing?.placeOfLoading?.zipCode,
      departure_lat: routing?.placeOfLoading?.lat,
      departure_lng: routing?.placeOfLoading?.lng,

      arrival: routing?.placeOfDelivery?.label,
      arrival_country: routing?.placeOfDelivery?.countryAbbr,
      arrival_city: routing?.placeOfDelivery?.city,
      arrival_cap: routing?.placeOfDelivery?.zipCode,
      arrival_lat: routing?.placeOfDelivery?.lat,
      arrival_lng: routing?.placeOfDelivery?.lng,

      client_id: summary?.client?.value,
      client_address_id: null,
      contacts: summary?.contacts?.map((contact) => contact.value),

      [mode?.cargoKey]:
        mode?.cargoKey === 'packages'
          ? cargo?.is_checked_packaging_details
            ? cargo?.[mode?.cargoKey].map((p) => ({
                box_type: p?.box_type,
                number: p?.quantity,
                width: p?.width,
                length: p?.length,
                height: p?.height,
                weight: p?.weight,
                type_of_weight: cargo.type_of_weight,
                is_stackable: p?.is_stackable,
              }))
            : []
          : cargo?.[mode?.cargoKey].map((p) => ({
              box_type: p?.box_type,
              quantity: p?.quantity,
              reefer: p?.reefer,
              reefer_temperature: p?.reefer_temperature?.join(','),
            })),
    }

    if (entity === 'quotation') {
      data.departure_airport = mode?.shipmentType === 2 ? routing?.departure_airport : null
      data.arrival_airport = mode?.shipmentType === 2 ? routing?.arrival_airport : null
      data.departure_port = mode?.shipmentType === 1 ? routing?.departure_port : null
      data.arrival_port = mode?.shipmentType === 1 ? routing?.arrival_port : null
    }

    return data
  }, [cargo, entity, flagStatus, mode, routing, summary, isEditMode])

  const handleSummaryStep = async () => {
    try {
      setUnknownError(null)
      setIsSubmitting(true)

      const result = isEditMode
        ? await editShipmentParam(entityId, '', submitData)
        : await createShipment(submitData)

      if (isEditMode) upsertShipmentData(result.shipment)
      showSuccessFlag(result.message)

      setTimeout(() => {
        navigate(isEditMode ? `/panel/${entity}s/${entityId}` : entity === 'quotation'? `/panel/quotations/${result.quotation.id_auto}/finance/edit` : `/panel/${entity}s`)
      }, 100)

    } catch (e) {
      console.error(e)
      setIsSubmitting(false)

      if ([400].includes(e.response?.status)) {
        setUnknownError('Something wrong in payload!')
      } else {
        setUnknownError(true)
      }
    }
  }

  return !routing?.placeOfLoading ? (
    <Navigate to={steps[0].path} replace />
  ) : (
    <>
      <WizardForm name="summary" onSubmit={handleSummaryStep}>
        {({ setValue }) => (
          <>
            <ShipmentCreationCargoHeader />

            <div className="shadow rounded p-4 mt-4">
              <RowTable
                rows={[
                  {
                    title: 'Service',
                    content: modes.find((m) => m.id === routing?.modeType)?.service,
                  },
                  mode?.cargoKey === 'packages'
                    ? [
                        {
                          title: 'Total packages',
                          content: `${submitData.total_collies} Packages`,
                        },
                        {
                          title: 'Total weight',
                          content: `${submitData.weight} Kgs`,
                        },
                        {
                          title: 'Total volume',
                          content: `${submitData.cbm} CBM`,
                        },
                        {
                          title: 'Temperature control',
                          content: submitData.reefer ? (
                            <>
                              <Lozenge appearance="moved">Yes</Lozenge>
                              <small className="text-neutral-200 ml-2">
                                {submitData.reefer_temperature
                                  .split(',')
                                  ?.map((t) => (t > 0 ? `+${t}` : t))
                                  .join(', ')}
                                {' °C'}
                              </small>
                            </>
                          ) : (
                            <Lozenge appearance="default">NO</Lozenge>
                          ),
                        },
                      ]
                    : [],
                  {
                    title: 'Dangerous cargo',
                    content: submitData.adr_goods ? (
                      <Lozenge appearance="moved">Yes</Lozenge>
                    ) : (
                      <Lozenge appearance="default">NO</Lozenge>
                    ),
                  },
                  submitData.adr_goods
                    ? [
                        {
                          title: 'UN Number',
                          content: submitData.adr_number,
                        },
                        {
                          title: 'Class',
                          content: findOption(
                            getEnumerationOptionsByKey('adr_classes'),
                            cargo?.adr_class
                          )?.label,
                        },
                      ]
                    : [],
                  {
                    title: 'Cargo insurance',
                    content: submitData.insurance ? (
                      <Lozenge appearance="moved">Yes</Lozenge>
                    ) : (
                      <Lozenge appearance="default">NO</Lozenge>
                    ),
                  },
                  submitData.insurance
                    ? {
                        title: 'Cargo value',
                        content: (
                          <>
                            {formatMonetaryNumber(submitData.goods_value)}{' '}
                            {
                              findOption(getEnumerationOptionsByKey('currencies'), cargo?.currency)
                                ?.value
                            }
                          </>
                        ),
                      }
                    : [],
                ].flat()}
              />
            </div>

            {createElement(mode?.cargoSummary)}

            {!isEditMode && (
              <div className="mt-8">
                <h3>Client</h3>

                <Card className="mt-2">
                  <Field
                    isRequired
                    type="select"
                    name="client"
                    label="Client"
                    className="!mt-0"
                    onValueChange={() => {
                      setValue('contacts', null)
                    }}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <RegistrySelect {...fieldProps} registryType="client" />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>

                  {summary?.client?.value && (
                    <Field type="select" name="contacts" className="!mt-4" label="Contacts">
                      {({ fieldProps, error }) => (
                        <>
                          <Select
                            isMulti
                            options={registryContactsOptions}
                            isLoading={clientContactsIsLoading}
                            {...fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  )}
                </Card>
              </div>
            )}

            {unknownError && <WarningBanner text={unknownError} />}

            <div className="flex justify-end mt-4">
              <Button type="submit" appearance="default" component={Link} to={prevStep.path}>
                Previous step
              </Button>

              <LoadingButton
                type="submit"
                className="ml-2"
                appearance="primary"
                isLoading={isSubmitting}
              >
                {isEditMode ? 'Update' : 'Submit'}
              </LoadingButton>
            </div>
          </>
        )}
      </WizardForm>
    </>
  )
}

export default ShipmentCreationSummaryPage
