import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

// API
import { lookupLine } from 'features/lines/api/requests'
import { submitQuotationRoutes } from 'api/shipments'

// Hooks
import useShipment from 'hooks/useShipment'
import useLineRoutes from 'features/lines/hooks/useLineRoutes'

// Atlaskit
import EmptyState from '@atlaskit/empty-state'
import Button, { LoadingButton } from '@atlaskit/button'

// User Interface
import { Grid, Timeline, TimelineItem } from '@royan-co/user-interface'

// Components
import Card, { CardTitle } from 'components/UI/Card'
import Divider from 'components/UI/Divider'
import RowTable from 'components/UI/RowTable'
import TableItem from 'components/UI/TableItem'

const QuotationRoutes = ({ callback }) => {
  const { shipmentId, quotationId } = useParams()
  const [line, setLine] = useState()
  const [selectedRoutes, setSelectedRoutes] = useState([])

  const entityId = quotationId || shipmentId
  const { shipment: entity } = useShipment(entityId)
  const { routes } = useLineRoutes(line?.id, { enabled: !!line?.id })

  const submitRoutesMutation = useMutation(submitQuotationRoutes.bind(null, entityId))

  useEffect(() => {
    if (line) return
    if (!entity?.departure_country || !entity?.arrival_country) return

    const findLine = async () => {
      try {
        const res = await lookupLine(entity?.departure_country, entity?.arrival_country)
        setLine(res)
      } catch (e) {
        console.error(e)
        // TODO: Handle not found for line
      }
    }

    findLine()
  }, [line, entity?.departure_country, entity?.arrival_country])

  const filteredRoutes = useMemo(() => {
    if (!line || !routes || !entity) return

    return routes.filter(
      (route) =>
        route.shipment_type === entity.shipment_type &&
        route.type_of_collies === entity.type_of_collies
    )
  }, [line, routes, entity])

  useEffect(() => {
    if (!filteredRoutes) return

    const defaultRoutes = filteredRoutes.filter((route) => route.default).map((route) => route.id)

    setSelectedRoutes((prevSelectedRoutes) => {
      const areRoutesSame =
        prevSelectedRoutes.length === defaultRoutes.length &&
        prevSelectedRoutes.every((id, index) => id === defaultRoutes[index])

      return areRoutesSame ? prevSelectedRoutes : defaultRoutes
    })
  }, [filteredRoutes])

  const toggleRouteSelection = (routeId) => {
    setSelectedRoutes((prevSelected) =>
      prevSelected.includes(routeId)
        ? prevSelected.filter((id) => id !== routeId)
        : [...prevSelected, routeId]
    )
  }

  const getRates = () => {
    let data = {
      routes: selectedRoutes,
    }

    submitRoutesMutation.mutate(data, {
      onError: (e) => {
        console.error(e)
      },
      onSuccess: (res) => {
        callback(res?.routes)
      },
    })
  }

  return (
    <>
      {filteredRoutes?.length ? (
        <>
          <div className="text-lg">Please choose your routes for calculation.</div>
          <Grid>
            {filteredRoutes.map((route, index) => (
              <Grid.Col sm={4} key={index}>
                <Card>
                  <CardTitle>{route?.name}</CardTitle>
                  <RowTable
                    rows={[
                      {
                        title: 'Transit time',
                        content: <TableItem condition={route?.transit_time} />,
                      },
                    ]}
                  />
                  <Divider className="mb-5" />
                  <Timeline>
                    {route?.legs?.map((leg, index) => (
                      <TimelineItem key={index} title={`${leg?.departure} to ${leg?.arrival}`} />
                    ))}
                  </Timeline>
                  <Button
                    appearance={selectedRoutes.includes(route.id) ? 'primary' : 'default'}
                    shouldFitContainer
                    onClick={() => toggleRouteSelection(route.id)}
                  >
                    {selectedRoutes.includes(route.id)
                      ? 'Deselect this route'
                      : 'Select this route'}
                  </Button>
                </Card>
              </Grid.Col>
            ))}
          </Grid>

          <div className="mt-10">
            <LoadingButton
              appearance="primary"
              onClick={getRates}
              isLoading={submitRoutesMutation.isLoading}
            >
              Proceed
            </LoadingButton>
          </div>
        </>
      ) : (
        <>
          <EmptyState header="No routes available" />
        </>
      )}
    </>
  )
}

export default QuotationRoutes
